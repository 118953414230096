<template>
	<div class='container'>
		<div class="box">
			<p>尊敬的用户您好,登录小程序注册为会员,可享受更多优惠</p>
			<img :src="code" alt="">
		</div>
	</div>
	
</template>


<script>
	import vueQr from 'vue-qr'
	export default{
		components: {
			vueQr
		},
		data(){
			return {
				code:''
			}
		},
		mounted() {
			if (!localStorage.getItem('index_code')) {
				this.weChatCode()
			} else {
				this.code = this.$domain+localStorage.getItem('index_code');
			}
		
		},
		methods:{
			//获取微信二维码
			//获小程序二维码
			async weChatCode() {
				if (localStorage.getItem('device')) {
					let scene = localStorage.getItem('device') + '?' + localStorage.getItem('model') +
						'?index';
			
			
			
					console.log('abc', scene)
					this.model = localStorage.getItem('model');
					//return false
					let data = {
						type: '1',
						scene: scene,
						ctype: '1',
	
					}
					console.log('参数', data);
			
					let res = await this.$api.weChatCode(data)
					if (res.code == '200') {
						this.code = res.data
						localStorage.setItem('index_code', this.code)
					}
				} else {
					this.$message.error('请先绑定设备编号')
			
				}
			
			
			},
		}
		
	}
</script>

<style lang="scss" scoped>
	.box{
		padding-top:100px;
		p{
			font-size: 35px;
			text-align: center;
			color:#fff;
		}
		img{
			margin: 0px auto;
			margin-top: 50px;
			display: block;
			width:250px;
		}
	}
</style>
